import React from 'react';
import './FormGroupRadio.css';

import FormRadio from './FormRadio';

/*

*/

export default function FormGroupRadio(props) {
  const {
    k,
    name,
    values,
    formGroupData,
    onDataChange,
  } = props;

  // Receive the value, add the key
  const handleDataChange = (value) => {
    onDataChange({
      'k': k,
      'v': value,
      });
  }
  
  return (
    <div
      className="FormGroupRadio"
    >
      <h2>{name}</h2>
      {values.map(value => (
        <FormRadio
          k={k}
          key={value.value} // internal
          name={name}
          value={value.value}
          label={value.name}
          id={value.value}
          checked={formGroupData === value.value} // true/false
          onDataChange={handleDataChange}
        />
      ))}
    </div>
  );
}
