import React from 'react';
import './Form.css';

import FormGroupRadio from './FormGroupRadio';

/*
  The qualification-elements' display's logic is here
*/

export default function Form(props) {
  const {
    qualificationScreens,
    formData,
    orderedKeys,
    setFormData,
  } = props;

  // Make form submit unavailable
  const handleSubmit = (e) => {
    e.preventDefault();
  }
  
  // formData is not ordered (it's an object)
  // but its elements' order is important
  // (it is managed by orderedKeys which is an array).
  // In case an element is changed,
  // every following (right siblings) elements must be set to undefined.
  // Receives an object {k:key, v:value}
  const handleFormDataUpdate = (data) => {
    // New formData:
    let o = {};
    // Switch:
    let reset = false;
    
    for (let i = 0; i < orderedKeys.length; i++) {
      let k = orderedKeys[i];
      if (k === data['k']) {
        o[k] = data['v'];
        reset = true;
      } else {
        if (reset === true) {
          o[k] = undefined; 
        } else {
          o[k] = formData[k];
        }
      }
    }
    setFormData(o);
  }
  
  // Helper : given a qs and formData, return the sub
  const getSub = (qs, formData) => {
    let key = qs['key'];
    let values = qs['values'];
    
    for (let i = 0; i < values.length; i++) {
      // This is the selected one :
      if (values[i]['value'] === formData[key]) {
        // It has a sub
        if (values[i]['sub'] !== false) {
          return values[i]['sub'];
        }
      }
    }
    return false;
  }
  
  // Compute what has to be displayed (Mods = Modalities):
  // For each formData element which isn't undefined (but last),
  // will display the expected FormGroupRadio.
  const buildMods = (qualificationScreens, formData) => {
    let mods = []
    
    // Run over qualificationsScreens to identify which one to display.
    // Initial qualificationScreen is... the first one.
    // Next one is :
    // defined by the current's value which matches with formData
    // see : "identify next qualificationScreen" below
    let i = 0;
    let qs = Object.values(qualificationScreens[i])[0];
    
    while (i < qualificationScreens.length) {
      let key = qs['key'];
      let name = qs['name'];
      let values = qs['values'];
      mods.push({
        'key': key,
        'name': name,
        'values': values,
        'selected': formData[key] === undefined ? undefined : formData[key],
      });
      
      // Only one undefined is allowed (one question at a time)!
      if(formData[key] === undefined) {
        break;
      }
      
      // Identify next qualificationScreen
      let sub = getSub(qs, formData);
      
      if (sub !== false) {
        // Search this sub in qualificationScreens
        while (i < qualificationScreens.length) {
          // Current i is for current qs: we search from the next one.
          // But, is there any ?
          if (i === qualificationScreens.length - 1) {
            return mods;
          }
          i++;
          let nextqsKey = Object.keys(qualificationScreens[i])[0];
          if (nextqsKey === sub) {
            qs = Object.values(qualificationScreens[i])[0];
            // We got our qs, break the small while an return to the big one
            break;
          }
        }
      } else {
        return mods;
      }
    }
    return mods;
  }

  const [mods, setMods] = React.useState(() => {
    const initialState = buildMods(qualificationScreens, formData);
    return initialState;
  });
  
  React.useEffect(() => {
    const mods = buildMods(qualificationScreens, formData);
    setMods(mods);
  }, [formData]);

  return (
    <form
      onSubmit={handleSubmit}
    >
      <div className="Form">
        {mods.map(mod => (
          <FormGroupRadio
            k={mod.key} // "key" is reserved :-)
            key={mod.key} // internal
            name={mod.name}
            values={mod.values}
            formGroupData={formData[mod.key]}
            onDataChange={handleFormDataUpdate}
          />
        ))}
      </div>
    </form>
  );
}
