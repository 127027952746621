import React, { useEffect } from 'react';
import './Player.css';

import {Helmet} from "react-helmet";

/*

*/

export default function Player(props) {
  const {
    projectUuid,
    qualification,
    pleasePlay,
  } = props;

  const createPlayer = () => {
    console.log('Player.js says : Creating player');
    // Editor preview dialog work with one SDK instace stored in window object.
    // We don't want to create multiple SDK instace
    if (window.SDK) {
      window.SDK = null;
      // return;
    }
    const { SkewerSDK } = window;

    if (!projectUuid) {
      console.error('Missing projectUuid', projectUuid);
      return;
    }

    if (!SkewerSDK?.SKPlayer) {
      console.error('Missing SkewerSDK.SKPlayer');
      return;
    }

    const SKPlayerOption = {
      projectUUID: projectUuid,
      playerContainerElement: '#skwrPlayer',
      width: '100%',
      height: '100%',
      // volume: 1,
      isPreview: false,
      // onPlayerEvent: handlePlayerEvent.bind(this),
      // onSDKEvent: handleSDKEvent.bind(this),
    };

    // Instanciation de SKPlayer()
    window.SDK = new SkewerSDK.SKPlayer(SKPlayerOption);
  };

  // Update SDK options
  // Usefull if the HTML reference used at SDK creation change
  // or if references in this component changed
  const updateSDKOption = () => {
    if (window?.SDK) {
      window.SDK.updateOption({
        projectUUID: projectUuid,
        playerContainerElement: '#skwrPlayer',
        width: '100%',
        height: '100%',
        // volume: playerVolume,
        isPreview: false,
        // onPlayerEvent: this.handlePlayerEvent.bind(this),
        // onSDKEvent: this.handleSDKEvent.bind(this),
      });
    }
  };

  const sendQualification = (qualif) => {
    console.log('Player.js says : sendQualification fired');
    if (!qualif) {
      console.error('sendQualification() qualification is not good ', qualif);
      return;
    }

    // If SDK instance doesn't exist, we create one
    window.SDK = null;
    destroyIframe()
    createPlayer();
    
    if (window?.SDK?.sendQualification) {
      window.SDK.sendQualification(qualif);
      console.log('Player.js says : window.SDK.sendQualification(qualif); with qualif : ', qualif);
    }
  };


  React.useEffect(() => {
    console.log('Player.js says : useEffect [] fired (didMount)')
    // When component is mounted and a SDK instance exist
    // we need to update Player iframe HTML reference and event callback functions
    // passed at SDK creation.
    // Because these references may not be up to date
    if (window.SDK) {
      updateSDKOption();
      window.SDK.bindListeners();
    }
  }, []);
  
  
  React.useEffect(() => {
    console.log('Player.js says : useEffect [pleasePlay] fired (didUpdate)',
      '| pleasePlay\s value =', pleasePlay, 
      '| qualification =', qualification,
      )
    if (pleasePlay) {
      
      sendQualification(qualification);
    }
  }, [pleasePlay]);

  function destroyIframe() {
    const parent = document.querySelector('#skwrPlayer')
    // this.removeListeners();

    if (parent.firstChild) {
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
      }
    }
  }
  
  /*
  React.useEffect(() => {
    console.log('useEffect with return fired (willUnmount)')
    return function cleanup() {
      if (window.SDK) {
        window.SDK.removeListeners();
      }
    }
  });
  */

  return (
    <>
    <Helmet>
      <script src="https://api.skwr.io/sdk"></script>
    </Helmet>
    
    <div className="Player">
      <div id="skwrPlayer"></div>
    </div>
    </>
  );
}
