import React from 'react';
import './FormRadio.css';

/*

*/

export default function FormRadio(props) {
  const {
    name, // key
    value, // value
    label, // Value (human)
    id, // = value, used by <label>
    checked,
    onDataChange,
  } = props;

  const handleDataChange = (e) => {
    onDataChange(value);
  }

  return (
    <div className="FormRadio">
      <input
        type="radio"
        value={value}
        id={id}
        checked={checked}
        onChange={handleDataChange}
        name={name}
      />
      <label htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
