import React from 'react';
import './App.css';

import Form from './components/Form';
import Player from './components/Player';

/*

*/

const PROJECTUUID = 'd0bec9d5-0084-4e32-8f9d-752ac97ecebe';
// WARNING, validation's logic is in React.useEffect
// it is project-dependant and MUST be set by hand

const Saison = {
  'key': 'Saison',
  'name': 'Saison',
  'values': [
    {
      'value': 'Eté',
      'name': 'Été',
      'sub': 'Entreprises_ou_particuliers',
    },
    {
      'value': 'Hiver',
      'name': 'Hiver',
      'sub': 'Entreprises_ou_particuliers',
    },
  ]
};

const Entreprises_ou_particuliers = {
  'key': 'Entreprises_ou_particuliers',
  'name': 'Êtes-vous',
  'values': [
    {
      'value': 'MICE',
      'name': 'MICE',
      'sub': 'Nombre_étoiles',
    },
    {
      'value': 'Particuliers',
      'name': 'Particulier',
      'sub': 'Cible',
    },
  ]
}

const Cible = {
  'key': 'Cible',
  'name': 'Cible',
  'values': [
    {
      'value': 'Familles',
      'name': 'Famille',
      'sub': 'Chalets_ou_hotels',
    },
    {
      'value': 'Couples',
      'name': 'Couple',
      'sub': 'Chalets_ou_hotels',
    },
    {
      'value': 'Groupe_d-amis',
      'name': 'Groupe d\'amis',
      'sub': 'Chalets_ou_hotels',
    },
  ]
};

const Chalets_ou_hotels = {
  'key': 'Chalets_ou_hotels_?',
  'name': 'Chalet / Hôtel',
  'values': [
    {
      'value': 'Chalets',
      'name': 'Chalets',
      'sub': 'Passer_intro',
    },
    {
      'value': 'hotels',
      'name': 'Hôtels',
      'sub': 'Nombre_étoiles',
    },
  ]
};

const Nombre_étoiles = {
  'key': 'Nombre_étoiles',
  'name': 'Étoiles',
  'values': [
    {
      'value': '3_étoiles',
      'name': '3 étoiles',
      'sub': 'Hotels_3',
    },
    {
      'value': '4_étoiles',
      'name': '4 étoiles',
      'sub': 'Hotels_4',
    },
    {
      'value': '4+_étoiles',
      'name': '4 étoiles +',
      'sub': 'Hotels_4p',
    },
  ]
};

const Hotels_3 = {
  'key': 'Hotels',
  'name': 'Hôtels',
  'values': [
    {
      'value': 'Arbina',
      'name': 'Arbina',
      'sub': 'Passer_intro',
    },
  ]
};

const Hotels_4 = {
  'key': 'Hotels',
  'name': 'Hôtels',
  'values': [
    {
      'value': 'Le_Levanna',
      'name': 'Levanna',
      'sub': 'Passer_intro',
    },
    {
      'value': 'Les_Campanules',
      'name': 'Campanules',
      'sub': 'Passer_intro',
    },
    {
      'value': 'Montana_de_Tignes',
      'name': 'Montana',
      'sub': 'Passer_intro',
    },
    {
      'value': 'Carlina_Belle-Plagne',
      'name': 'Carlina',
      'sub': 'Passer_intro',
    },
  ]
};

const Hotels_4p = {
  'key': 'Hotels',
  'name': 'Hôtels',
  'values': [
    {
      'value': 'Voulez-vous',
      'name': 'Voulez-vous',
      'sub': 'Passer_intro',
    },
    {
      'value': 'Full_Life',
      'name': 'Full Life',
      'sub': 'Passer_intro',
    },
  ]
};

const Passer_intro = {
  'key': 'Passer_l-intro',
  'name': 'Intro',
  'values': [
    {
      'value': 'Non',
      'name': 'Oui',
      'sub': false,
    },
    {
      'value': 'Oui',
      'name': 'Non',
      'sub': false,
    },
  ]
};

// make it ordered:
// [{qualificationScreen: formKey}]
const qualificationScreens = [
  {'Saison': Saison},
  {'Entreprises_ou_particuliers': Entreprises_ou_particuliers},
  {'Cible': Cible},
  {'Chalets_ou_hotels': Chalets_ou_hotels},
  {'Nombre_étoiles': Nombre_étoiles},
  {'Hotels_3': Hotels_3},
  {'Hotels_4': Hotels_4},
  {'Hotels_4p': Hotels_4p},
  {'Passer_intro': Passer_intro},
];

/*
// Qualification Screens :
const num = {
  'key': 'num',
  'name': 'Num Values',
  'values': [
    {
      'value': 'slug1',
      'name': 'Value One',
      'sub': 'abc1', // mind the link to another qualification screen
    },
    {
      'value': 'slug2',
      'name': 'Value Two',
      'sub': 'abc1',
    },
    {
      'value': 'slug3',
      'name': 'Value Three',
      'sub': 'abc2',
    },
  ]
};

const abc1 = {
  'key': 'abc',
  'name': 'ABC Values',
  'values': [
    {
      'value': 'slugA',
      'name': 'Value A',
      'sub': 'ijk',
    },
    {
      'value': 'slugB',
      'name': 'Value B',
      'sub': 'ijk',
    },
    {
      'value': 'slugC',
      'name': 'Value C',
      'sub': 'ijk',
    },
  ]
};

const abc2 = {
  'key': 'abc',
  'name': 'CDE Values',
  'values': [
    {
      'value': 'slugD',
      'name': 'Value D',
      'sub': 'ijk',
    },
    {
      'value': 'slugE',
      'name': 'Value E',
      'sub': 'ijk',
    },
    {
      'value': 'slugF',
      'name': 'Value F',
      'sub': 'ijk',
    },
  ]
};

const ijk = {
  'key': 'ijk',
  'name': 'IJK Values',
  'values': [
    {
      'value': 'slugI',
      'name': 'Value I',
      'sub': false,
    },
    {
      'value': 'slugJ',
      'name': 'Value J',
      'sub': false,
    },
    {
      'value': 'slugK',
      'name': 'Value K',
      'sub': 'xyz',
    },
  ]
};

const xyz = {
  'key': 'xyz',
  'name': 'XYZ Values',
  'values': [
    {
      'value': 'slugX',
      'name': 'Value X',
      'sub': false,
    },
    {
      'value': 'slugY',
      'name': 'Value Y',
      'sub': false,
    },
    {
      'value': 'slugZ',
      'name': 'Value Z',
      'sub': false,
    },
  ]
};

// make it ordered:
// [{qualificationScreen: formKey}]
const qualificationScreens = [
  {'num': num},
  {'abc1': abc1},
  {'abc2': abc2},
  {'ijk': ijk},
  {'xyz': xyz},
];
*/

export default function App() {
  /*
  Initialize formData:
    [i, j, k, l, m] with:
      i.key = 'a'
      j.key = 'b'
      k.key = 'c'
      l.key = 'c'
      m.key = 'd'
      (mind k.key == l.key)
    becomes:
      ['a', 'b', 'c', 'd']
  */
  const getKeysFromQS = () => {
    let a = []
    for (let i = 0; i < qualificationScreens.length; i++) {
      let value = Object.values(qualificationScreens[i])[0]
      let key = value['key']

      if ( ! a.includes(key)) {
        a.push(key)
      }
    }
    return a
  }

  const orderedKeys = getKeysFromQS();
  
  const [formData, setFormData] = React.useState(() => {
    // ['a', 'b', 'c', 'd']
    // becomes:
    // {'a':undefined, 'b':undefined, 'c':undefined, 'd':undefined}
    let o = {}
    for (let i = 0; i < orderedKeys.length; i++) {
      o[orderedKeys[i]] = undefined;
    }
    return o;
  });
  
  const [pleasePlay, setPleasePlay] = React.useState(false);
  
  React.useEffect(() => {
    console.log('formData update detected');
    let lastKey = orderedKeys[orderedKeys.length - 1];
    if (formData[lastKey] === undefined) {
      pleasePlay && setPleasePlay(false);
    } else {
      pleasePlay || setPleasePlay(true);
      const e = document.getElementById('skwrPlayer');
      e.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formData]);

  return (
    <div className="App">
      <Form
        qualificationScreens={qualificationScreens}
        orderedKeys={orderedKeys}
        formData={formData}
        setFormData={setFormData}
      />
      {true
        ? <Player
          projectUuid={PROJECTUUID}
          qualification={formData}
          pleasePlay={pleasePlay}
        />
        : null
      }
    </div>
  );
}
